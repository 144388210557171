//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Modal, { ModalMixin } from "@/components/modals/layouts/Large.vue";
import Wrapper from "./Wrapper";
export default {
  mixins: [ModalMixin],
  data() {
    return {
      limit: null
    };
  },
  mounted() {},
  methods: {
    onOpen({
      limit
    }) {
      if (typeof limit === "number") {
        this.limit = limit;
      }
    }
  },
  components: {
    Modal,
    Wrapper
  }
};